import { DatePipe, NgClass } from '@angular/common';
import { Component, HostBinding, Input, OnInit, model } from '@angular/core';
import { RouterLink } from '@angular/router';

import { Blog } from '@alan-apps/data-access';
import {
  I18nLinkPipe,
  LoadingBlockDirective,
  SafePipe,
  SymbolFlagComponent,
} from '@nghedgehog/angular-ui';

import { ImgPipe } from '../../../../core/pipe/img.pipe';
import { input } from '@angular/core';

@Component({
  selector: 'app-blog-card',
  templateUrl: './blog-card.component.html',
  styleUrls: ['./blog-card.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    I18nLinkPipe,
    RouterLink,
    LoadingBlockDirective,
    SymbolFlagComponent,
    DatePipe,
    SafePipe,
    ImgPipe,
  ],
})
export class BlogCardComponent implements OnInit {
  data = input<Blog | undefined>(undefined, { alias: 'blog' });
  nonLink = input(false);

  routerLink = model([]);

  unclickable = input(false);

  class = input<string | undefined>();
  noInfo = input(false);
  noDate = input(false);

  ngOnInit() {
    if (this.routerLink().length === 0) {
      this.routerLink.set(['/blog', this.data().id]);
    }
  }
}
