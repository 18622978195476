<a
  class="relative flex bg-white elevation-2 rounded-4xl"
  [ngClass]="class()"
  [routerLink]="(nonLink() ? undefined : routerLink()) | i18nLink"
>
  <article
    class="relative overflow-hidden d-inline-block w-full rounded-4xl"
    [ngClass]="{ 'bottom-fuzzy': !noInfo() }"
  >
    <img
      class="object-cover aspect-video w-full non-drag"
      [src]="
        data().cover | img: 'hq' | safe: 'url' : 'assets/img/default_blog.png'
      "
      [attr.loading]="'lazy'"
    />

    @if (!noInfo()) {
      <div>
        <section class="mx-4 pt-2">
          <h5 class="border-top border-bottom mb-2">{{ data().subject }}</h5>
          <p>
            {{ data().description }}
          </p>
        </section>
        <footer class="mx-4 flex m-2"></footer>
      </div>
    }
    <!--  *ngIf="tag" -->
    <ngx-symbol-flag
      size="bigger"
      position="right"
      class="text-white bg-primary z-[3]"
    >
      {{ data().category.name }}
    </ngx-symbol-flag>
  </article>

  @if (!noDate() && data().info.updatedAt; as thisDate) {
    <div class="tag absolute">
      <h5 class="p-4 bg-accent m-0 text-white text-center rounded-2.5xl">
        {{ thisDate | date: 'yyyy' }}<br />
        {{ thisDate | date: 'MM' }}/{{ thisDate | date: 'dd' }}
      </h5>
    </div>
  }
</a>
